<template>
  <div
    :style="isMobile() ? 'height: 67vh' : 'height: 94vh; margin-top: 6vh;'"
    class="font-sans relative flex md:h-full w-full"
  >

    <slot />
  </div>
</template>

<script>
import { isMobile } from "@/global/services/mixins";
export default {
  name: "Content",
  setup() {
    return { isMobile };
  }
};
</script>
