import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "../../../interfaces";
import { authentication } from "@/global/services/authentication";
import { errorLogging } from "@/global/services/errorLogging";
import { period } from "@/timeregistration/services/period";
import { ref } from "vue";
import { units } from "./units";
import { employee } from "./employee";

let oldUserRequestsDetacher: any = undefined;
let userDeclinedRequestsDetacher: any = undefined;
let userAcceptedRequestsDetacher: any = undefined;
let userDeletedRequestsDetacher: any = undefined;
let allRequestsDetacher: any = undefined;
let allAcceptedRequestsDetacher: any = undefined;
let allDeletedRequestsDetacher: any = undefined;
let allDeclinedRequestsDetacher: any = undefined;
const allRequests = ref([] as Array<firebase.firestore.DocumentSnapshot>);

export const requests = {
  createRequest: function(
    id: any = null,
    dataChanged: any,
    dataBefore: any,
    changeType: string
  ) {
    const dataCopy = Object.assign({}, dataChanged);
    const dataBeforeCopy = Object.assign({}, dataBefore);
    let data: any;
    if (id !== null) {
      data = {
        requestBy: employee.getId(),
        requestFor: units.getSelectedUnitId(),
        dataId: id,
        dataCollection: "/timeRegistrations",
        changeType,
        dataBefore: dataBeforeCopy,
        data: dataCopy,
        created: firebase.firestore.FieldValue.serverTimestamp()
      };
    } else {
      data = {
        requestBy: employee.getId(),
        requestFor: units.getSelectedUnitId(),
        dataCollection: "/timeRegistrations",
        changeType,
        data: dataCopy,
        created: firebase.firestore.FieldValue.serverTimestamp()
      };
    }
    data.accepted = data.declined = data.deleted = null;
    return new Promise((resolve, reject) => {
      console.log(data);
      firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests")
        .add(data)
        .then((docRefRequest: any) => {
          return docRefRequest.get().then(() => {
            resolve("Anmodning er registreret");
          });
        })
        .catch((error: any) => reject(error.message));
    });
  },

  createVacationRequest: function(data: any) {
    return new Promise((resolve, reject) => {
      const dataCopy = Object.assign({}, data);
      dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();
      dataCopy.accepted = dataCopy.declined = dataCopy.deleted = null;
      console.log(dataCopy);
      firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests")
        .add(dataCopy)
        .then((docRefRequest: any) => {
          return docRefRequest.get().then(() => {
            resolve("Anmodning er registreret");
          });
        })
        .catch((error: any) => reject(error.message));
    });
  },

  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateUserRequests: function(): Promise<number | string> {
    allRequests.value = [];
    oldUserRequestsDetacher !== undefined ? oldUserRequestsDetacher() : null;
    return new Promise((resolve, reject) => {
      const oldRequstsCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests")
        .where("requestBy", "==", employee.getId())
        .where("accepted", "==", null)
        .where("declined", "==", null)
        .where("deleted", "==", null)
        .orderBy("created", "desc");
      oldUserRequestsDetacher = oldRequstsCollectionRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot for requests: size ${querySnap.size}`
          );
          resolve(querySnap.size);
          querySnap.docChanges().forEach(
            (docChange: any) => {
              const docReference = docChange.doc;

              if (docChange.type === "added") {
                allRequests.value.push(docReference);
              }
              if (docChange.type === "modified") {
                const idx = allRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                allRequests.value.splice(idx, 1, docReference);
              }

              if (docChange.type === "removed") {
                const idx = allRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                allRequests.value.splice(idx, 1);
              }
            },
            (error: any) => reject(error.message)
          );
        }
      );
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateUserDeclinedRequests: function(): Promise<number | string> {
    return new Promise((resolve, reject) => {
      if (!userDeclinedRequestsDetacher) {
        const userDeclinedRequstsCollectionRef = firebase
          .firestore()
          .collection("farms/" + authentication.getFarmId() + "/requests")
          .where("requestBy", "==", employee.getId())
          .where("declined", "!=", null);
        userDeclinedRequestsDetacher = userDeclinedRequstsCollectionRef.onSnapshot(
          (querySnap: any) => {
            console.log(
              `Received query snapshot for requests: size ${querySnap.size}`
            );
            resolve(querySnap.size);
            querySnap.docChanges().forEach(
              (docChange: any) => {
                const docReference = docChange.doc;

                if (docChange.type === "added") {
                  allRequests.value.push(docReference);
                }
                if (docChange.type === "modified") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1, docReference);
                }

                if (docChange.type === "removed") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1);
                }
              },
              (error: any) => reject(error.message)
            );
          }
        );
      } else {
        resolve("Listener already running");
      }
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateUserAcceptedRequests: function(): Promise<number | string> {
    return new Promise((resolve, reject) => {
      if (!userAcceptedRequestsDetacher) {
        const userAcceptedRequstsCollectionRef = firebase
          .firestore()
          .collection("farms/" + authentication.getFarmId() + "/requests")
          .where("requestBy", "==", employee.getId())
          .where("accepted", "!=", null);
        userAcceptedRequestsDetacher = userAcceptedRequstsCollectionRef.onSnapshot(
          (querySnap: any) => {
            console.log(
              `Received query snapshot for requests: size ${querySnap.size}`
            );
            resolve(querySnap.size);
            querySnap.docChanges().forEach(
              (docChange: any) => {
                const docReference = docChange.doc;

                if (docChange.type === "added") {
                  allRequests.value.push(docReference);
                }
                if (docChange.type === "modified") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1, docReference);
                }

                if (docChange.type === "removed") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1);
                }
              },
              (error: any) => reject(error.message)
            );
          }
        );
      } else {
        resolve("Listener already running");
      }
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateUserDeletedRequests: function(): Promise<number | string> {
    return new Promise((resolve, reject) => {
      if (!userDeletedRequestsDetacher) {
        const userDeletedRequstsCollectionRef = firebase
          .firestore()
          .collection("farms/" + authentication.getFarmId() + "/requests")
          .where("requestBy", "==", employee.getId())
          .where("deleted", "!=", null);
        userDeletedRequestsDetacher = userDeletedRequstsCollectionRef.onSnapshot(
          (querySnap: any) => {
            console.log(
              `Received query snapshot for requests: size ${querySnap.size}`
            );
            resolve(querySnap.size);
            querySnap.docChanges().forEach(
              (docChange: any) => {
                const docReference = docChange.doc;

                if (docChange.type === "added") {
                  allRequests.value.push(docReference);
                }
                if (docChange.type === "modified") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1, docReference);
                }

                if (docChange.type === "removed") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1);
                }
              },
              (error: any) => reject(error.message)
            );
          }
        );
      } else {
        resolve("Listener already running");
      }
    });
  },

  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateAllRequests: function(): Promise<number | string> {
    allRequests.value = [];
    allRequestsDetacher !== undefined ? allRequestsDetacher() : null;
    return new Promise((resolve, reject) => {
      const requstsCollectionRef = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests")
        .where("requestFor", "==", units.getSelectedUnitId())
        .where("accepted", "==", null)
        .where("declined", "==", null)
        .where("deleted", "==", null)
        .orderBy("created", "desc");
      allRequestsDetacher = requstsCollectionRef.onSnapshot(
        (querySnap: any) => {
          console.log(
            `Received query snapshot for requests: size ${querySnap.size}`
          );
          resolve(querySnap.size);
          querySnap.docChanges().forEach(
            (docChange: any) => {
              const docReference = docChange.doc;
              if (docChange.type === "added") {
                allRequests.value.push(docReference);
              }
              if (docChange.type === "modified") {
                const idx = allRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                allRequests.value.splice(idx, 1, docReference);
              }
              if (docChange.type === "removed") {
                const idx = allRequests.value.findIndex(
                  (x: any) => x.id === docReference.id
                );
                allRequests.value.splice(idx, 1);
              }
            },
            (error: any) => reject(error.message)
          );
        }
      );
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateAcceptedAllRequests: function(): Promise<number | string> {
    return new Promise((resolve, reject) => {
      if (!allAcceptedRequestsDetacher) {
        const requstsAcceptedCollectionRef = firebase
          .firestore()
          .collection("farms/" + authentication.getFarmId() + "/requests")
          .where("requestFor", "==", units.getSelectedUnitId())
          .where("accepted", "!=", null);
        allAcceptedRequestsDetacher = requstsAcceptedCollectionRef.onSnapshot(
          (querySnap: any) => {
            console.log(
              `Received query snapshot for requests: size ${querySnap.size}`
            );
            resolve(querySnap.size);
            querySnap.docChanges().forEach(
              (docChange: any) => {
                const docReference = docChange.doc;
                if (docChange.type === "added") {
                  allRequests.value.push(docReference);
                }
                if (docChange.type === "modified") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1, docReference);
                }
                if (docChange.type === "removed") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1);
                }
              },
              (error: any) => reject(error.message)
            );
          }
        );
      } else {
        resolve("Listener already running");
      }
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateDeletedAllRequests: function(): Promise<number | string> {
    return new Promise((resolve, reject) => {
      if (!allDeletedRequestsDetacher) {
        const requstsDeletedCollectionRef = firebase
          .firestore()
          .collection("farms/" + authentication.getFarmId() + "/requests")
          .where("requestFor", "==", units.getSelectedUnitId())
          .where("deleted", "!=", null);
        allDeletedRequestsDetacher = requstsDeletedCollectionRef.onSnapshot(
          (querySnap: any) => {
            console.log(
              `Received query snapshot for requests: size ${querySnap.size}`
            );
            resolve(querySnap.size);
            querySnap.docChanges().forEach(
              (docChange: any) => {
                const docReference = docChange.doc;
                if (docChange.type === "added") {
                  allRequests.value.push(docReference);
                }
                if (docChange.type === "modified") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1, docReference);
                }
                if (docChange.type === "removed") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1);
                }
              },
              (error: any) => reject(error.message)
            );
          }
        );
      } else {
        resolve("Listener already running");
      }
    });
  },
  /**
   * Gets all requests made on the farm
   * @param arrayValue Vue array refference value to populate with documents containing requests
   * @returns Length of the final array if success or error message on error
   */
  updateDeclinedAllRequests: function(): Promise<number | string> {
    return new Promise((resolve, reject) => {
      if (!allDeclinedRequestsDetacher) {
        const requstsDeclinedCollectionRef = firebase
          .firestore()
          .collection("farms/" + authentication.getFarmId() + "/requests")
          .where("requestFor", "==", units.getSelectedUnitId())
          .where("declined", "!=", null);
        allDeclinedRequestsDetacher = requstsDeclinedCollectionRef.onSnapshot(
          (querySnap: any) => {
            console.log(
              `Received query snapshot for requests: size ${querySnap.size}`
            );
            resolve(querySnap.size);
            querySnap.docChanges().forEach(
              (docChange: any) => {
                const docReference = docChange.doc;
                if (docChange.type === "added") {
                  allRequests.value.push(docReference);
                }
                if (docChange.type === "modified") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1, docReference);
                }
                if (docChange.type === "removed") {
                  const idx = allRequests.value.findIndex(
                    (x: any) => x.id === docReference.id
                  );
                  allRequests.value.splice(idx, 1);
                }
              },
              (error: any) => reject(error.message)
            );
          }
        );
      } else {
        resolve("Listener already running");
      }
    });
  },

  getAllRequests: function(reference = false) {
    if (reference) {
      return allRequests;
    } else return allRequests.value;
  },

  /**
   * Deletes a request
   * @param requestDocumentId Vue array refference value to populate with documents containing requests
   * @returns "Anmodning er slettet" if success or error message on error
   */
  deleteRequest: function(requestDocumentId: string) {
    return new Promise((resolve, reject) => {
      const requestReference = firebase
        .firestore()
        .collection("farms/" + authentication.getFarmId() + "/requests");
      const docRef = requestReference.doc(requestDocumentId);

      docRef
        .get()
        .then((docSnap: any) => {
          return docRef
            .update({
              deleted: {
                by: employee.getId(),
                time: firebase.firestore.FieldValue.serverTimestamp()
              }
            })
            .then(() => {
              return docRef
                .get()
                .then((docSnap: any) => {
                  resolve("Anmodning er slettet");
                })
                .catch((error: any) => reject(error.message));
            })
            .catch((error: any) => reject(error.message));
        })
        .catch((error: any) => reject(error.message));
    });
  },

  /**
   * Accept a request
   * @param requestDocumentId Id of the request that should be accepted
   * @returns "Anmodning er godkendt" if success or error message on error
   */
  acceptRequest: function(
    requestDocumentReference: firebase.firestore.DocumentReference,
    requestDocument: firebase.firestore.DocumentSnapshot
  ) {
    const dataCopy = Object.assign({}, requestDocument.data()?.data);
    console.log(requestDocument.data());
    // Burde struktureres så dokumentet opdateres eller oprettes først for anmodning godkendes
    return new Promise((resolve, reject) => {
      requestDocumentReference
        .update({
          accepted: {
            by: employee.getId(),
            time: firebase.firestore.FieldValue.serverTimestamp()
          }
        })
        .then(() => {
          if (requestDocument.data()?.dataId) {
            dataCopy.updated = firebase.firestore.FieldValue.serverTimestamp();
            firebase
              .firestore()
              .collection(
                "farms/" +
                  authentication.getFarmId() +
                  requestDocument.data()?.dataCollection
              )
              .doc(requestDocument.data()?.dataId)
              .update(requestDocument.data()?.data);
            resolve("Anmodning godkendt");
          } else {
            dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();
            firebase
              .firestore()
              .collection(
                "farms/" +
                  authentication.getFarmId() +
                  requestDocument.data()?.dataCollection
              )
              .add(requestDocument.data()?.data);
            resolve("Anmodning godkendt");
          }
        })
        .catch(error => errorLogging.setError(error));
    });
  },

  /**
   * Accept a request
   * @param requestDocumentId Id of the request that should be accepted
   * @returns "Anmodning er godkendt" if success or error message on error
   */
  acceptVacationRequest: function(
    requestDocument: firebase.firestore.DocumentSnapshot,
    vacationDays: number
  ) {
    const dataCopy = Object.assign({}, requestDocument.data()?.data);
    dataCopy.created = firebase.firestore.FieldValue.serverTimestamp();
    dataCopy.vacationDays = vacationDays;
    // Burde struktureres så dokumentet opdateres eller oprettes først for anmodning godkendes
    console.log(dataCopy);
    return new Promise((resolve, reject) => {
      requestDocument.ref
        .update({
          accepted: {
            by: authentication.getUserUid(),
            time: firebase.firestore.FieldValue.serverTimestamp()
          }
        })
        .then(() => {
          firebase
            .firestore()
            .collection("farms/" + authentication.getFarmId() + "/vacations")
            .add(dataCopy);
          resolve("Anmodning godkendt");
        })
        .catch(e => {
          reject(e.message);
        });
    });
  },

  /**
   * Decline a request
   * @param requestDocumentId Id of the request that should be accepted
   * @returns "Anmodning er godkendt" if success or error message on error
   */
  declineRequest: function(
    requestDocumentReference: firebase.firestore.DocumentReference
  ) {
    return new Promise((resolve, reject) => {
      requestDocumentReference
        .update({
          declined: {
            by: employee.getId(),
            time: firebase.firestore.FieldValue.serverTimestamp()
          }
        })
        .then(() => resolve("Anmodning afvist"))
        .catch(error => errorLogging.setError(error));
    });
  }
};
