<template>
  <transition>
    <button
      class="transition-all rounded-md text-white shadow-md px-3 md:px-6 py-2 font-semibold md:tracking-wider"
    >
      <span v-if="icon" class="mr-2 w-8 -ml-3 md:-ml-6">
        <i class="fas" :class="icon"></i>
      </span>
      <p>{{ label }}</p>
    </button>
  </transition>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Button",
  props: { label: String, icon: String },
  components: {}
};
</script>

<style scoped>
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
}
</style>
