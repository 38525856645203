<template>
  <div
    :style="[isMobile() ? 'height: 18vh' : 'height: 6vh']"
    class="md:shadow-md md:absolute md:top-0 md:left-0 w-full flex flex-col md:flex-row items-center justify-center md:justify-between md:px-3 md:bg-gradient-to-r from-blue-darkest to-green-darkest"
  >
    <h1 v-if="!isMobile()" class="text-2xl font-semibold flex">
      <span class="flex flex-shrink-0 justify-center items-center mr-4">
        <i class="fas" :class="icon"></i>
      </span>
      {{ title }}
    </h1>
    <div
      v-if="isMobile() && farmHeader"
      class="flex flex-col justify-between items-center px-3 mt-3 w-full"
    >
      <div
        v-if="unitsList.length > 1"
        @click="selectingUnit = !selectingUnit"
        class="flex flex-col tracking-wider relative items-center justify-center w-10/12"
        :class="[filteredUnitsList.length > 0 ? 'cursor-pointer' : '']"
      >
        <span class="relative flex bg-white rounded-md px-3 items-center">
          <span class="text-yellow mr-3">
            <i class="fas fa-warehouse"></i>
          </span>
          <div class="items-center flex mt-1">
            <p class="text-green-darkest font-semibold">
              {{ profile.selectedUnit }}
            </p>
            <span
              v-if="filteredUnitsList.length > 0"
              class="flex justify-center items-center text-yellow w-6 h-6 rounded-full"
            >
              <i class="fas fa-chevron-down"></i>
            </span>
          </div>
          <ul
            v-if="selectingUnit"
            class="absolute -ml-3 rounded-md top-8 divide-y-2 flex flex-col text-center bg-white font-semibold text-green-darkest shadow-md w-full z-40"
          >
            <li
              class="py-1 hover:bg-yellow hover:text-white rounded-md"
              v-for="unit in filteredUnitsList"
              :key="unit.id"
              :value="unit.data().unitName"
              @click="unitSelected(unit)"
            >
              {{ unit.data().unitName }}
            </li>
          </ul>
        </span>
      </div>
      <span
        class=""
        v-if="timerDemoDB && location.hostname === 'demo.farmhelp.dk'"
      >
        <p class="text-green text-lg tracking-wide flex">
          Data reset in:
          <span class="text-white ml-1 mr-1">{{ demoMinutes }}</span> min
          <span class="text-white ml-1 w-6 block">{{
            demoSeconds > 9
              ? demoSeconds.toString()
              : "0" + demoSeconds.toString()
          }}</span>
          sec
        </p></span
      >
      <div class=" w-48 text-green flex items-center justify-between">
        <span
          @click="decreasePeriod()"
          class="h-10 w-10 flex justify-center items-center text-yellow hover:text-green transition-all cursor-pointer"
        >
          <i class="fas fa-chevron-left"></i>
        </span>
        <p class="font-bold text-green">{{ t(`wageperiod`) }}</p>
        <span
          @click="increasePeriod()"
          class="h-10 w-10 flex justify-center items-center"
          :class="[
            isEndPeriod()
              ? 'text-green'
              : 'text-yellow hover:text-green transition-all cursor-pointer'
          ]"
        >
          <i class="fas fa-chevron-right"></i>
        </span>
      </div>
      <div class="flex w-full justify-center">
        <DatePicker
          v-model="start"
          class="text-green-darkest bg-white "
          :disabled="true"
        />
        <p class="mx-3 text-green">-</p>
        <DatePicker
          v-model="end"
          class="text-green-darkest bg-white"
          :disabled="true"
        />
      </div>
    </div>
    <h1 v-if="isMobile()" class="text-xl font-semibold flex mt-3">
      <span class="flex flex-shrink-0 justify-center items-center mr-4">
        <i class="fas" :class="icon"></i>
      </span>
      {{ title }}
    </h1>
    <div v-if="!isMobile()" class="flex items-center">
      <span
        class="mr-12"
        v-if="timerDemoDB && location.hostname === 'demo.farmhelp.dk'"
      >
        <p class="text-green text-2xl tracking-wide flex">
          Data reset in:
          <span class="text-white ml-3 mr-1">{{ demoMinutes }}</span> min
          <span class="text-white ml-3 w-8 block">{{
            demoSeconds > 9
              ? demoSeconds.toString()
              : "0" + demoSeconds.toString()
          }}</span>
          sec
        </p></span
      >
      <!-- <NotificationBell
        v-if="location.hostname !== 'app.farmhelp.dk'"
        class="mr-3"
      /> -->
      <a
        v-if="false"
        target="_blank"
        href="https://farmhelp.dk/Helpcenter/Overview"
      >
        <span
          class="relative flex justify-center items-center bg-green rounded-full w-8 h-8 z-10 hover:bg-yellow cursor-pointer mr-3"
        >
          <i class="fas fa-question"></i>
        </span>
      </a>
      <span class="relative">
        <img
          class="cursor-pointer"
          @click="chooseLang = !chooseLang"
          :src="lang.url"
        />
        <ul v-show="chooseLang" class="absolute z-50 mt-3">
          <li
            v-for="url in urls.filter(u => u.lang !== lang.lang)"
            :key="url"
            class="opacity-60 hover:opacity-100"
          >
            <img
              class="cursor-pointer"
              @click="
                locale = url.lang;
                lang = url;
                chooseLang = false;
              "
              :src="url.url"
            />
          </li>
        </ul>
      </span>
    </div>
  </div>
</template>

<script>
import DatePicker from "@/components/DatePicker";
import { period } from "@/timeregistration/services/period";
import { isMobile } from "@/global/services/mixins";
import { ref, watch, computed, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { authentication } from "@/global/services/authentication";
import { employee } from "@/global/services/employee";
import { units } from "@/global/services/units";

export default {
  name: "Header",
  props: {
    title: String,
    icon: String,
    farmHeader: {
      type: Boolean,
      default: true
    }
  },
  components: { DatePicker },
  setup() {
    const { t, locale } = useI18n({ useScope: "global" });

    const state = {
      location,
      locale,
      lang: ref({
        lang: "dk",
        url: require("@/assets/dk.png")
      }),
      chooseLang: ref(false),
      urls: [
        {
          lang: "dk",
          url: require("@/assets/dk.png")
        },
        {
          lang: "en",
          url: require("@/assets/en.png")
        }
      ],
      employeeDoc: employee.get(true),
      profile: authentication.getUserProfile(true),
      selectingUnit: ref(false),
      selectedUnit: units.getSelectedUnit(true),
      dateDemoDB: computed(() => {
        const dbDate =
          state.selectedUnit.value !== undefined &&
          location.hostname === "demo.farmhelp.dk"
            ? state.selectedUnit.value.data().dbCreationDate.toDate()
            : null;
        if (dbDate) {
          return dbDate.getTime();
        } else {
          return null;
        }
      }),
      timerDemoDB: ref(0),
      demoMinutes: ref(0),
      demoSeconds: ref(0),
      unitsList: units.getUnitsList(true),
      filteredUnitsList: computed(() => {
        if (authentication.isAdmin()) {
          return state.unitsList.value;
        } else {
          return state.unitsList.value.filter(
            e =>
              e.data().unitName !== authentication.getUserSelectedUnit() &&
              state.employeeDoc.value.data().units.includes(e.id)
          );
        }
      })
    };

    if (location.hostname === "demo.farmhelp.dk") {
      setInterval(() => {
        const goal = 15 * 60 * 1000;
        const now = new Date();
        state.timerDemoDB.value = now.getTime() - state.dateDemoDB.value;
        state.demoMinutes.value = Math.floor(
          (goal - state.timerDemoDB.value) / (1000 * 60)
        );
        state.demoSeconds.value = Math.floor(
          (goal -
            state.timerDemoDB.value -
            state.demoMinutes.value * 60 * 1000) /
            1000
        );
      }, 1000);
    }

    const functions = {
      isMobile,
      setLang: function(url) {
        state.lang.value = url;
        state.chooseLang.value = false;
      },
      decreasePeriod: period.decreasePeriod,
      increasePeriod: period.increasePeriod,
      isEndPeriod: period.isEndPeriod,
      unitSelected: function(unit) {
        units.updateSelectedUnit(unit);
        state.selectingUnit.value = false;
      }
    };

    watchEffect(() => {
      state.lang.value = state.urls.find(u => u.lang === locale.value);
    });

    watch(state.lang, () => {
      authentication.updateUser(authentication.getUserUid(), {
        lang: state.lang.value.lang
      });
    });

    return { t, ...state, ...functions, ...period };
  }
};
</script>

<style scoped></style>
