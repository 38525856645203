<template>
  <div class="flex flex-col h-12 md:h-14 relative">
    <span
      class="flex items-center
        cursor-pointer text-sm w-full pr-6 h-12 md:h-14"
      @click="selectingEmployee = !selectingEmployee"
    >
      <span
        :class="[selectingEmployee ? 'bg-green' : 'bg-green-dark']"
        class="flex flex-shrink-0 justify-center items-center text-xl rounded-full w-12 h-12 md:w-14 md:h-14 z-20 shadow-md"
      >
        <i class="fas fa-user"></i>
      </span>
      <span
        class="bg-white pl-6 rounded-md -ml-3 w-full text-green-darkest z-10"
      >
        <p
          v-if="modelValue !== undefined && modelValue !== '*'"
          class="block text-base mr-2 font-bold md:my-2"
        >
          {{ modelValue.data().firstName }} {{ modelValue.data().lastName }}
        </p>
        <p
          v-if="modelValue === '*' && all"
          class="block text-base mr-2 font-bold md:my-2"
        >
          {{ t("all") }}
        </p>
        <p
          v-if="modelValue === '*' && !all"
          class="block text-base mr-2 font-bold md:my-2"
        >
          {{ t("choose") }}
        </p>
      </span>
      <span class="flex justify-center w-6 -ml-8 items-center text-green z-50">
        <i class="fas fa-chevron-down text-lg mx-2"></i>
      </span>
    </span>
    <ul
      v-if="selectingEmployee"
      class="absolute top-20 h-64 overflow-y-scroll z-50 w-full divide-y-2 flex flex-col text-center bg-white text-green shadow-md rounded-md"
    >
      <template v-if="unitEmployeeList !== []">
        <li
          v-if="modelValue !== undefined && modelValue !== '*' && all"
          @click="$emit('update:modelValue', employeeSelected('*'))"
          class=" flex items-center
        cursor-pointer w-full h-8 md:h-10 text-green-darkest hover:bg-green hover:text-white"
        >
          <span class="rounded-md z-0 w-full">
            <p class="block text-base font-bold">
              Alle
            </p>
          </span>
        </li>
        <li
          v-for="employee in unitEmployeeList.filter(
            e => e.id !== modelValue.id && e.data().deleted === null
          )"
          :key="employee.id"
          :value="employee"
          @click="$emit('update:modelValue', employeeSelected(employee))"
          class=" flex items-center
        cursor-pointer w-full h-8 md:h-10 text-green-darkest hover:bg-green hover:text-white"
        >
          <span class="rounded-md z-0 w-full">
            <p class="block text-base font-bold">
              {{ employee.data().firstName }} {{ employee.data().lastName }}
            </p>
          </span>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { units } from "@/global/services/units";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { employee } from "@/global/services/employee";

export default {
  name: "SelectorEmployee",
  emits: ["update:modelValue"],
  props: {
    modelValue: [Object, String],
    all: {
      default: false
    }
  },
  setup(props, { emit }) {
    const router = useRouter();
    const { t } = useI18n({
      messages: {
        en: {
          choose: "Choose an employee",
          all: "All employees"
        },
        dk: {
          choose: "Vælg en ansat",
          all: "Alle ansatte"
        }
      }
    });
    const state = {
      selectingEmployee: ref(false),
      unitEmployeeList: units.getSelectedUnitEmployees(true)
    };

    const employeeSelected = employee => {
      state.selectingEmployee.value = false;
      return employee;
    };

    watchEffect(() => {
      if (
        router.currentRoute.value.query.employeeId &&
        state.unitEmployeeList.value.length !== 0
      ) {
        const emp = state.unitEmployeeList.value.find(e => {
          return e.id === router.currentRoute.value.query.employeeId;
        });

        emit("update:modelValue", employeeSelected(emp));
      } else if (state.unitEmployeeList.value.length !== 0) {
        emit("update:modelValue", employeeSelected("*"));
      }
    });
    return { t, ...state, employeeSelected };
  }
};
</script>

<style scoped></style>
