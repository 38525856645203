<template>
  <div class="">
    <transition name="slide-fade">
      <div class="flex flex-col">
        <span
          class="transition-all inline-flex shadow-md h-10"
          :class="[focus ? '' : effect]"
        >
          <span
            v-if="icon || label"
            class="transition-all flex justify-center items-center font-bold text-center text-white px-2 rounded-l"
            :class="bg + ' ' + labelWidth"
          >
            <span
              v-if="icon"
              class="flex justify-center items-center text-white w-10 h-10"
            >
              <i class="fas" :class="icon"></i>
            </span>
            {{ label }}
          </span>
          <input
            v-bind:value="modelValue"
            @input="$emit('update:modelValue', $event.target.value)"
            @focus="focus = !focus"
            @blur="focus = !focus"
            class="outline-none text-center font-bold md:text-sm text-green px-3 rounded-r"
            :class="[inputWidth, !icon || !label ? 'rounded-l' : '']"
            :type="type"
            :placeholder="placeholder"
          />
        </span>
        <span
          v-if="error"
          class="bg-white rounded-md text-red-500 w-full font-semibold inline-flex mt-3 mr-4 px-3 py-1"
        >
          <span class="flex justify-center items-center text-lg mr-3">
            <i class="fas fa-exclamation-circle"></i>
          </span>
          <p>
            {{ errorMessage }}
          </p>
        </span>
      </div>
    </transition>
  </div>
</template>

<script>
import { reactive, toRefs, watchEffect } from "vue";
export default {
  name: "Input",
  emits: ["update:modelValue"],
  props: {
    modelValue: String || Number,
    label: String,
    labelWidth: String,
    inputWidth: String,
    placeholder: String,
    icon: String,
    type: {
      type: String,
      default: "text"
    },
    effect: String,
    error: Boolean,
    errorMessage: String
  },
  setup(props) {
    const state = reactive({
      focus: false,
      bg: ""
    });

    watchEffect(() => {
      if (props.error) {
        state.bg = "bg-red-500";
      } else if (state.focus) {
        state.bg = "bg-green";
      } else {
        state.bg = "bg-green-dark";
      }
    });
    return { ...toRefs(state) };
  }
};
</script>

<style scoped>
::-webkit-input-placeholder {
  text-align: center;
}

:-moz-placeholder {
  text-align: center;
}

/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease-out;
}

.slide-fade-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(20px);
}
</style>
