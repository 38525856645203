<template>
  <teleport to="body">
    <div
      @click.prevent="$emit('closeModal')"
      v-if="visible"
      class="flex justify-center items-center z-50 fixed top-0 w-full h-full bg-blue-darkest text-white bg-opacity-80"
    >
      <div
        class="bg-green-darkest p-6 w-full md:w-1/2 rounded-md relative flex-col flex flex-wrap justify-center items-center"
        @click.stop=""
      >
        <span
          class="text-white absolute top-3 right-6 text-xl cursor-pointer hover:text-green"
          @click.prevent="$emit('closeModal')"
        >
          <i class="fas fa-times"></i>
        </span>
        <slot></slot>
      </div>
    </div>
  </teleport>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Modal",
  components: {},
  emits: ["closeModal"],
  props: {
    visible: {
      default: false
    }
  }
};
</script>
